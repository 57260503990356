import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Box, Heading, VStack, FormControl, Button, HStack, Icon, Spinner, Flex, IconButton, FlatList, Text, Pressable } from "native-base";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {NotificationSettingsBanner} from '../assets/images';
import Select from '@material-ui/core/Select';
import {materialColor, listMainTextColor, underlayColor, searchBarBorderColor, borderBottomColor, brandColor, headerColor, borderColor, titleTextColor, deleteColor} from '../constants/colors';


const styles = {
  paper: {
    flex: 1,
    // padding: 5
  },
  loading: {
    position: 'absolute',
    left: 420,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    //backgroundColor: '#0000ff47',
    display:'flex'
  },
};

class NotificationSettings extends React.Component {
  constructor() {
    super();
    this.state = {
      cellProvider1: 'Select',
      cellProvider2: 'Select',
      guestArrival: '',
      registeredDevices: [],
      notiOpts: [],
      providerOpts: [],
      loading: false
    };
  }

  closeRow(rowMap, rowKey) {
    if (rowMap[rowKey]) {
      rowMap[rowKey].closeRow();
    }
  }

  deleteRow = async guestID => {
    const {deleteNotificationSetting} = this.props;
    // console.log('@deleteRow');
    // console.log(guestID);
    this.setState({loading: true});
    await deleteNotificationSetting(guestID);
    await this.loadData();
  };

  loadData = async () => {
    const {getNotificationSetting} = this.props;
    const {error, response} = await getNotificationSetting();
    const thisThat = this;
    if (!error) {
      thisThat.setState({
        cellProvider1: response.data.notificationSettings[0].cellProvider1,
        cellProvider2: response.data.notificationSettings[0].cellProvider2,
        guestArrival: response.data.notificationSettings[0].guestArrival,
        registeredDevices: response.data.registeredDevices,
      });
    }
    this.setState({loading: false});
  };

  createArray = (items) => {
    let newArr = [];
    for (let x in items){
      let newItem = {
        label: items[x],
        value: items[x],
      };
      newArr.push(newItem);
    }
    return newArr;
  }

  getValue(setting) {
    const {loginData} = this.props;
    if (loginData) {
      const appOptions = loginData.appOptions;
      for (let x of appOptions) {
        if (x.setting === setting) {
          return x.value;
        }
      }
    }
  }

  componentDidMount = async () => {
    this.setState({loading: true});
    const {globalData} = this.props;
    const {dropdownArrivalNotificationTypes, dropdownCellCarrierTypes} = globalData;
    const thisThat = this;
    this.setState({
      notiOpts: thisThat.createArray(dropdownArrivalNotificationTypes),
      providerOpts: thisThat.createArray(dropdownCellCarrierTypes),
    })

    await this.loadData();
    this.setState({loading: false});
  };

  handleChangePicker = name => event => {
    // console.log(name);
    // console.log(event.target.value);
    this.setState({
      ...this.state,
      [name]: event.target.value,
    });
  };

  onSubmit=()=> {
    const {
      // registeredDevices,
      cellProvider1,
      cellProvider2,
      guestArrival,
      // notiOpts,
      // providerOpts,
    } = this.state;

    let allData = {
      cellProvider1,
      cellProvider2,
      guestArrival,
    };
    this.saveData(allData);
  }

  saveData = async allData => {
    const {editNotificationSetting} = this.props;
    this.setState({loading: true});
    await editNotificationSetting(allData);
    await this.loadData();
  };


  render() {

    const {
      registeredDevices,
      cellProvider1,
      cellProvider2,
      guestArrival,
      notiOpts,
      providerOpts,
      loading
    } = this.state;
    const { classes } = this.props;

    return (
      <div className={classes.paper}>
        <img
          style={{width: window.innerWidth, height: 230, resizeMode: 'contain', margin: 'auto', alignSelf: 'center'}}
          src={NotificationSettingsBanner}
          alt={'NotificationSettingsBanner'}
        />
        <div style={{backgroundColor: headerColor, padding: 20, marginTop: 5, borderRadius: 10, zIndex: 10, elevation: 10, marginBottom: 20}}>
          <VStack style={{borderWidth: 1, borderRadius: 5, borderColor: borderColor, backgroundColor: 'white', marginTop: 15}}>
            <HStack space={3} w='97%' alignItems='center' justifyContent='space-between'>
              <Heading ml={5} mt={5} _dark={{
                color: titleTextColor
              }} color={titleTextColor} fontWeight="medium" size="md">
                Notification Setting
              </Heading>
              <Pressable
                mt={5}
                style={{borderRadius: 5, width: '15%', justifyContent: 'center', alignItems: 'center', background: 'linear-gradient(180deg, #0B7645 30%, #042B1C 90%)'}}
                onPress={() => this.onSubmit()}
                disabled={loading}
                underlayColor={underlayColor}>
                <HStack space={3} justifyContent="space-between" alignItems="center" p="3">
                  <Icon as={<FontAwesomeIcon icon={['fas', 'plus']} size="lg" color={"white"}/>}/>
                  <Heading _dark={{
                      color: "white"
                  }} color="white" fontWeight="medium" size="sm">
                    Save
                  </Heading>
                </HStack>
              </Pressable>
            </HStack>
            <VStack space={5} w="70%" mt={5} ml={5}>
              <HStack space={3} w='37%' alignItems='center' justifyContent='space-between'>
                <FormControl>
                  <FormControl.Label>Guest Arrival Notifications</FormControl.Label>
                  <Select
                    native
                    value={guestArrival}
                    onChange={this.handleChangePicker('guestArrival')}
                    inputProps={{
                      name: 'guestArrival',
                      id: 'outlined-age-native-simple',
                    }}
                  >
                    {notiOpts.map((item, index) => {
                      return <option value={item.value} key={index}>{item.label}</option>
                    })}
                  </Select>
                </FormControl>
                {this.getValue('HideCellProviders') && (
                  <FormControl>
                    <FormControl.Label>Provider for Cell Phone #1</FormControl.Label>
                    <Select
                      native
                      value={cellProvider1}
                      onChange={this.handleChangePicker('cellProvider1')}
                      inputProps={{
                        name: 'cellProvider1',
                        id: 'cellProvider1',
                      }}
                    >
                      {providerOpts.map((item, index) => {
                        return <option value={item.value} key={index}>{item.label}</option>
                      })}
                    </Select>
                  </FormControl>
                )}
                {this.getValue('HideCellProviders') && (
                  <FormControl>
                    <FormControl.Label>Provider for Cell Phone #2</FormControl.Label>
                    <Select
                      native
                      value={cellProvider2}
                      onChange={this.handleChangePicker('cellProvider2')}
                      inputProps={{
                        name: 'cellProvider2',
                        id: 'cellProvider2',
                      }}
                    >
                      {providerOpts.map((item, index) => {
                        return <option value={item.value} key={index}>{item.label}</option>
                      })}
                    </Select>
                  </FormControl>
                )}
              </HStack>
              {/* <Button w="55%" mt="5" mb="5" _light={{ bg: materialColor, _text: { color: "white" } }}
                _dark={{ bg: materialColor, _text: { color: "white" } }}
                disabled={loading}
                style={{borderRadius: 20}}
                onPress={() => this.onSubmit()}>
                Save
              </Button> */}
            </VStack>

            <Heading _dark={{
              color: titleTextColor
            }} color={titleTextColor} fontWeight="medium" size="md" mt={10} mb={5} ml={5}>
              Registered device(s) for push notifications
            </Heading>
            <VStack ml={5} mr={5} mb={10} style={{width:"95%", borderWidth: 1, borderRadius: 5, borderColor: borderColor, backgroundColor: 'white', marginTop: 5}}>
              <HStack space={2} alignItems="center">
                {/* <Text style={{
                    color: listMainTextColor,
                    fontSize: 16,
                    fontWeight: 'bold',
                    width: '6%',
                    padding: 7,
                    textTransform: 'capitalize',
                    borderRightColor: borderColor,
                    borderRightWidth:1,
                    alignSelf: 'center',
                    textAlign: 'center'
                }}>S.No.</Text> */}
                <Text
                  style={{
                      color: listMainTextColor,
                      fontSize: 16,
                      fontWeight: 'bold',
                      padding: 7,
                      width: '50%',
                      textTransform: 'capitalize',
                      borderRightColor: borderColor,
                      borderRightWidth:1,
                      alignSelf: 'center',
                      // textAlign: 'center'
                  }}>
                  Device Name
                </Text>
                <Text
                  style={{
                      color: listMainTextColor,
                      fontSize: 16,
                      fontWeight: 'bold',
                      padding: 7,
                      width: '35%',
                      textTransform: 'capitalize',
                      borderRightColor: borderColor,
                      borderRightWidth:1,
                      alignSelf: 'center',
                      // textAlign: 'center'
                  }}>
                  Platform
                </Text>
                <Text
                  style={{
                      color: listMainTextColor,
                      fontSize: 16,
                      fontWeight: 'bold',
                      // padding: 7,
                      width: '9%',
                      textTransform: 'capitalize',
                      // borderRightColor: borderColor,
                      // borderRightWidth:1,
                      alignSelf: 'center',
                      // textAlign: 'center'
                  }}>
                  Action
                </Text>
              </HStack>
              <FlatList
                style={{width:"100%", borderColor: borderColor, borderWidth: 1}}
                data={registeredDevices}
                renderItem={({item, index}) => (
                  <Pressable
                    style={{borderBottomColor: borderBottomColor, borderBottomWidth: 1}}
                    // onPress={() => {this.props.history.push({pathname: 'add-edit-guest', state: {data: item}});}}
                    underlayColor={underlayColor}>
                    <Box flex="1">
                      <HStack space={2} alignItems="center">
                        {/* <Text style={{
                            color: listMainTextColor,
                            fontSize: 16,
                            width: '6%',
                            padding: 7,
                            textTransform: 'capitalize',
                            borderRightColor: borderColor,
                            borderRightWidth:1,
                            alignSelf: 'center',
                            textAlign: 'center'
                        }}>{index+1}</Text> */}
                        <Text
                          style={{
                              color: listMainTextColor,
                              fontSize: 16,
                              padding: 7,
                              width: '50%',
                              textTransform: 'capitalize',
                              borderRightColor: borderColor,
                              borderRightWidth:1,
                              alignSelf: 'center',
                              // textAlign: 'center'
                          }}>
                          {item?.deviceName}
                        </Text>
                        <Text
                          style={{
                              color: listMainTextColor,
                              fontSize: 16,
                              padding: 7,
                              width: '35%',
                              textTransform: 'capitalize',
                              borderRightColor: borderColor,
                              borderRightWidth:1,
                              alignSelf: 'center',
                              // textAlign: 'center'
                          }}>
                          {item.platform}
                        </Text>
                        <VStack w="8.8%">
                          <IconButton icon={<Icon as={<FontAwesomeIcon icon={['fas', 'trash']} size="lg" color={deleteColor}/>}/>} onPress={() => {this.deleteRow(item.id)}} />
                        </VStack>
                      </HStack>
                    </Box>
                  </Pressable>
                )}
              />
            </VStack>
          </VStack>
        </div>
        {loading && (
          <div className={classes.loading}>
            <Spinner size="lg" />
          </div>
        )}
      </div>
    );
  }
}

NotificationSettings.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(NotificationSettings);
