import React from 'react';
import { Box, Heading, VStack, HStack, Spinner, Flex, FlatList, Text, Pressable } from "native-base";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SearchBar from "material-ui-search-bar";
import {RegisteredVehiclesBanner} from '../assets/images';
import {materialColor, listMainTextColor, underlayColor, searchBarBorderColor, borderBottomColor, brandColor, headerColor, borderColor, deleteColor, lightGreen} from '../constants/colors';

const styles = {
  paper: {
    flex: 1,
    // padding: 5
  },
  loading: {
    position: 'absolute',
    left: 420,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    //backgroundColor: '#0000ff47',
    display:'flex'
  },
};


class RegisteredVehiclesScreen extends React.Component {
  constructor() {
    super();
    this.arrayholder = [];
    this.state = {
      allData: [],
      value: '',
      loading: false
    };
  }

  async componentDidMount() {
    const {getRegisteredVehicles} = this.props;
    this.setState({loading: true});
    await getRegisteredVehicles();
    this.setState({allData: this.props.registeredVehicles});
    this.arrayholder = this.props.registeredVehicles;
    this.setState({loading: false});
  }

  search = text => {
    const newData = this.arrayholder.filter(item => {
      const itemData = `${item.make.toLowerCase()}`;
      const textData = text.toLowerCase();
      return itemData.indexOf(textData) > -1;
    });
    this.setState({
      allData: newData,
    });
  }

  render() {
    const {classes} = this.props;
    const {loading, allData} = this.state;

    return (
      <div className={classes.paper}>
        <img
          style={{width: window.innerWidth, height: 230, resizeMode: 'contain', margin: 'auto', alignSelf: 'center', borderRadius: 10}}
          src={RegisteredVehiclesBanner}
          alt={'RegisteredVehiclesBanner'}
        />
        <div style={{backgroundColor: headerColor, padding: 20, marginTop: 5, borderRadius: 10, zIndex: 10, elevation: 10, marginBottom: 20}}>
          <HStack space={2} justifyContent="space-between">
            <SearchBar
              style={{width: "40%", borderRadius: 5, borderWidth:1, borderColor: searchBarBorderColor}}
              value={this.state.value}
              //onChange={(newValue) => this.setState({ value: newValue })}
              onChange={(newValue) => this.search(newValue)}
              onCancelSearch={() => this.search('')}
              //onRequestSearch={() => this.search(this.state.value)}
            />
          </HStack>
          <VStack style={{borderWidth: 1, borderRadius: 5, borderColor: borderColor, backgroundColor: 'white', marginTop: 15}}>
            <HStack space={2} alignItems="center">
              {/* <Text style={{
                  color: listMainTextColor,
                  fontSize: 16,
                  fontWeight: 'bold',
                  width: '6%',
                  padding: 7,
                  textTransform: 'capitalize',
                  borderRightColor: borderColor,
                  borderRightWidth:1,
                  alignSelf: 'center',
                  textAlign: 'center'
              }}>S.No.</Text> */}
              <Text
                style={{
                    color: listMainTextColor,
                    fontSize: 16,
                    fontWeight: 'bold',
                    padding: 7,
                    width: '36%',
                    textTransform: 'capitalize',
                    borderRightColor: borderColor,
                    borderRightWidth:1,
                    alignSelf: 'center',
                    // textAlign: 'center'
                }}>
                Make
              </Text>
              <Text
                style={{
                    color: listMainTextColor,
                    fontSize: 16,
                    fontWeight: 'bold',
                    padding: 7,
                    width: '30%',
                    textTransform: 'capitalize',
                    borderRightColor: borderColor,
                    borderRightWidth:1,
                    alignSelf: 'center',
                    // textAlign: 'center'
                }}>
                Year
              </Text>
              <Text
                style={{
                    color: listMainTextColor,
                    fontSize: 16,
                    fontWeight: 'bold',
                    padding: 7,
                    width: '34%',
                    textTransform: 'capitalize',
                    borderRightColor: borderColor,
                    borderRightWidth:1,
                    alignSelf: 'center',
                    // textAlign: 'center'
                }}>
                Color
              </Text>
            </HStack>
            <FlatList
              style={{width:"100%", borderColor: borderColor, borderWidth: 1}}
              data={allData}
              renderItem={({item, index}) => (
                <Pressable
                  style={{borderBottomColor: borderBottomColor, borderBottomWidth: 1}}
                  onPress={() => {this.props.history.push({pathname: 'registered-vehicles-details', state: {data: item}})}}
                  underlayColor={underlayColor}>
                  <Box flex="1">
                    <HStack space={2} alignItems="center">
                      {/* <Text style={{
                          color: listMainTextColor,
                          fontSize: 16,
                          width: '6%',
                          padding: 7,
                          textTransform: 'capitalize',
                          borderRightColor: borderColor,
                          borderRightWidth:1,
                          alignSelf: 'center',
                          textAlign: 'center'
                      }}>{index+1}</Text> */}
                      <Text
                        style={{
                            color: listMainTextColor,
                            fontSize: 16,
                            padding: 7,
                            width: '36%',
                            textTransform: 'capitalize',
                            borderRightColor: borderColor,
                            borderRightWidth:1,
                            alignSelf: 'center',
                            // textAlign: 'center'
                        }}>
                        {item?.make}
                      </Text>
                      <Text
                        style={{
                            color: listMainTextColor,
                            fontSize: 16,
                            padding: 7,
                            width: '30%',
                            textTransform: 'capitalize',
                            borderRightColor: borderColor,
                            borderRightWidth:1,
                            alignSelf: 'center',
                            // textAlign: 'center'
                        }}>{item.manufactureYear}
                      </Text>
                      <Text
                        style={{
                            color: listMainTextColor,
                            fontSize: 16,
                            padding: 7,
                            width: '34%',
                            textTransform: 'capitalize',
                            borderRightColor: borderColor,
                            borderRightWidth:1,
                            alignSelf: 'center',
                            // textAlign: 'center'
                        }}>
                        {item?.color}
                      </Text>
                    </HStack>
                  </Box>
                </Pressable>
              )}
            />
            <Heading _dark={{
              color: "warmGray.200"
            }} color="coolGray.600" fontWeight="medium" size="sm" m={5} alignSelf='center'>
              This information is read-only
            </Heading>
          </VStack>
        </div>
        {loading && (
          <div className={classes.loading}>
            <Spinner size="lg" />
          </div>
        )}
      </div>
    );
  }
}
RegisteredVehiclesScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(RegisteredVehiclesScreen);
