import React from 'react';
import { Box, HStack, Icon, Spinner, Flex, FlatList, Text, Pressable, VStack } from "native-base";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {materialColor, borderBottomColor, underlayColor, listMainTextColor, borderColor, deleteColor} from '../constants/colors';

const styles = {
  loading: {
    position: 'absolute',
    left: 420,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    //backgroundColor: '#0000ff47',
    display:'flex'
  },
};

class DocumentList extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
    };
  }

  async componentDidMount() {
    const {getDocuments} = this.props;
    this.setState({loading: true});
    await getDocuments();
    this.setState({loading: false});
  }

  getPdf = async did => {
    // console.log('here',did);
    let thisThat = this;
    const {getPdf} = this.props;
    const {loading} = this.state;

    if (!loading) {
      // console.log('here1',did);
      //thisThat.refs.toast.show('Loading ...');
      thisThat.setState({loading: true});
      await getPdf(did);
      thisThat.setState({loading: false});
      this.props.history.push({pathname: 'dashboard/view-pdf', state: {}});
    }
  };

  render() {
    const {allDocuments, classes} = this.props;
    const {loading} = this.state;
    return (
      <Flex justifyContent="center" alignItems="center" p='5'>
        <FlatList
          style={{width:"100%", borderColor: borderColor, borderWidth: 1}}
          data={allDocuments}
          renderItem={({item, index}) => (
            <Pressable
              style={{borderBottomColor: borderColor, borderBottomWidth: 1}}
              disabled={loading}
              onPress={() => {this.getPdf(item.documentID)}}
              underlayColor={underlayColor}>
              <Box flex="1">
                <HStack space={2} alignItems="center">
                  {/* <Text style={{
                      color: listMainTextColor,
                      fontSize: 16,
                      width: 35,
                      padding: 7,
                      textTransform: 'capitalize',
                      borderRightColor: borderColor,
                      borderRightWidth:1,
                      alignSelf: 'center',
                      textAlign: 'center'
                  }}>{index+1}</Text> */}
                  <Text
                    style={{
                        color: listMainTextColor,
                        fontSize: "90%",
                        padding: 7,
                        width: '82%',
                        textTransform: 'capitalize',
                        borderRightColor: borderColor,
                        borderRightWidth:1,
                        alignSelf: 'center',
                        // textAlign: 'center'
                    }}>
                    {item?.documentTitle}
                  </Text>
                  <VStack w='18%' alignItems={'center'} justifyContent={'center'}>
                    <Icon as={<FontAwesomeIcon icon={['fas', 'file-pdf']} size="lg" color={deleteColor}/>}/>
                  </VStack>
                </HStack>
              </Box>
            </Pressable>
          )}
        />
        {loading && (
          <div className={classes.loading}>
            <Spinner size="lg" />
          </div>
        )}
      </Flex>
    );
  }
}
DocumentList.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(DocumentList);
