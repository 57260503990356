import React from 'react';
import PropTypes from 'prop-types';
import { Heading, VStack, FormControl, Flex, Spinner, Button, HStack, Icon, Pressable } from "native-base";
import { withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {RegisteredVehiclesBanner} from '../assets/images';
import {materialColor, listMainTextColor, underlayColor, searchBarBorderColor, borderBottomColor, brandColor, headerColor, borderColor, titleTextColor} from '../constants/colors';

const styles = {
  paper: {
    flex: 1,
    // padding: 5
  },
  loading: {
    position: 'absolute',
    left: 420,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    //backgroundColor: '#0000ff47',
    display:'flex'
  },
};

class RegisteredVehiclesDetails extends React.Component {
  constructor() {
    super();
    this.state = {
      make: '',
      model: '',
      year: '',
      color: '',
      licensePlate: '',
      device: '',
      loading: false
    };
  }

  componentDidMount() {
    const allData = this.props.history.location.state.data;
    // console.log('@RegisteredVehiclesDetails: ',allData);

    const thisComp = this;
    if (allData) {
      thisComp.setState({
        make: allData.make,
        model: allData.model,
        year: allData.manufactureYear,
        color: allData.color,
        licensePlate: allData.tag,
        //device: allData.gateName
      });
    }
  }

  render() {
    const {
      make,
      model,
      year,
      color,
      licensePlate,
      device,
      loading
    } = this.state;

    const { classes } = this.props;

    return (
      <div className={classes.paper}>
        <img
          style={{width: window.innerWidth, height: 230, resizeMode: 'contain', margin: 'auto', alignSelf: 'center'}}
          src={RegisteredVehiclesBanner}
          alt={'RegisteredVehiclesBanner'}
        />
        <div style={{backgroundColor: headerColor, padding: 20, marginTop: 5, borderRadius: 10, zIndex: 10, elevation: 10, marginBottom: 20}}>
          <VStack style={{borderWidth: 1, borderRadius: 5, borderColor: borderColor, backgroundColor: 'white', marginTop: 15}}>
            <HStack space={3} m={6} w='30%' alignItems='center' justifyContent='space-between' mb={3}>
              <FormControl>
                <FormControl.Label>
                  Make
                </FormControl.Label>
                <Heading _dark={{
                  color: titleTextColor
                }} color={titleTextColor} borderColor={borderColor} borderWidth="1" borderRadius="5" w="85%" h="10" pl="2" alignContent="center" fontWeight="medium" size="sm">
                  {make}
                </Heading>
              </FormControl>

              <FormControl>
                <FormControl.Label>
                  Model
                </FormControl.Label>
                <Heading _dark={{
                  color: titleTextColor
                }} color={titleTextColor} borderColor={borderColor} borderWidth="1" borderRadius="5" w="85%" h="10" pl="2" alignContent="center" fontWeight="medium" size="sm">
                  {model}
                </Heading>
              </FormControl>

              <FormControl>
                <FormControl.Label>
                  Year
                </FormControl.Label>
                <Heading _dark={{
                  color: titleTextColor
                }} color={titleTextColor} borderColor={borderColor} borderWidth="1" borderRadius="5" w="85%" h="10" pl="2" alignContent="center" fontWeight="medium" size="sm">
                  {year}
                </Heading>
              </FormControl>
            </HStack>
            <HStack space={3} m={6} w='30%' alignItems='center' justifyContent='space-between' mb={3}>
              <FormControl>
                <FormControl.Label>
                  Color
                </FormControl.Label>
                <Heading _dark={{
                  color: titleTextColor
                }} color={titleTextColor} borderColor={borderColor} borderWidth="1" borderRadius="5" w="85%" h="10" pl="2" alignContent="center" fontWeight="medium" size="sm">
                  {color}
                </Heading>
              </FormControl>

              <FormControl>
                <FormControl.Label>
                  License Plate
                </FormControl.Label>
                <Heading _dark={{
                  color: titleTextColor
                }} color={titleTextColor} borderColor={borderColor} borderWidth="1" borderRadius="5" w="85%" h="10" pl="2" alignContent="center" fontWeight="medium" size="sm">
                  {licensePlate}
                </Heading>
              </FormControl>

              <FormControl>
                <FormControl.Label>
                  Device #
                </FormControl.Label>
                <Heading _dark={{
                  color: titleTextColor
                }} color={titleTextColor} borderColor={borderColor} borderWidth="1" borderRadius="5" w="85%" h="10" pl="2" alignContent="center" fontWeight="medium" size="sm">
                  {device}
                </Heading>
              </FormControl>
            </HStack>
            <VStack space={3} m={6} w='95%' alignItems='flex-end'>
              <Pressable
                style={{borderRadius: 5, width: '20%', justifyContent: 'center', alignItems: 'center', background: 'linear-gradient(180deg, #0B7645 30%, #042B1C 90%)'}}
                onPress={()=>{this.props.history.goBack()}}
                disabled={loading}
                underlayColor={underlayColor}>
                <HStack space={3} justifyContent="space-between" alignItems="center" p="3">
                  {/* <Icon as={<FontAwesomeIcon icon={['fas', 'user-plus']} size="lg" color={"white"}/>}/> */}
                  <Heading _dark={{
                    color: "white"
                  }} color="white" fontWeight="medium" size="sm">
                    Back
                  </Heading>
                </HStack>
              </Pressable>
            </VStack>
          </VStack>
        </div>
        {loading && (
          <div className={classes.loading}>
            <Spinner size="lg" />
          </div>
        )}
      </div>
    );
  }
}
RegisteredVehiclesDetails.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(RegisteredVehiclesDetails);
