/* eslint-disable */
import React from 'react';
import Select from '@material-ui/core/Select';
import {Box, VStack, Flex, FormControl, Input, HStack, Pressable, Button, Icon, Modal, Text, FlatList, Spinner, Heading, IconButton} from 'native-base';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import {materialColor, listMainTextColor, underlayColor, searchBarBorderColor, borderBottomColor, brandColor, headerColor, borderColor, titleTextColor, deleteColor} from '../constants/colors';
import Snackbar from '@material-ui/core/Snackbar';
import {NotificationSettingsBanner} from '../assets/images';

const styles = {
  paper: {
    flex: 1,
    // padding: 5
  },
  loading: {
    position: 'absolute',
    left: 420,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    //backgroundColor: '#0000ff47',
    display:'flex'
  },
  fab: {
    position: 'absolute',
    bottom: 30,
    right: 40,
    zIndex: 10,
    elevation: 10
  },
};

class NotificationSettingsB extends React.Component {
  constructor() {
    super();
    this.state = {
      // cellProvider1: '',
      // cellProvider2: '',
      guestArrival: '',
      registeredDevices: [],
      notiOpts: [],
      // providerOpts: [],
      darkMode: false,
      // textColor: '#000000',
      deviceToken: 'xxx',
      // itemData: {},
      // deviceName: '',
      loading: false,
      alertOpen: false,
      message: '',
      messageType: '',
    };
  }

  askDelete = () => {
    Alert.alert(
      'Delete',
      'Are you sure?',
      [
        {
          text: 'Ask me later',
          onPress: () => console.log('Ask me later pressed'),
        },
        {
          text: 'Cancel',
          onPress: () => console.log('Cancel Pressed'),
          style: 'cancel',
        },
        {text: 'OK', onPress: () => console.log('OK Pressed')},
      ],
      {cancelable: false},
    );
  };

  closeRow(rowMap, rowKey) {
    if (rowMap[rowKey]) {
      rowMap[rowKey].closeRow();
    }
  }

  deleteRow = async guestID => {
    const {deleteNotificationSettingB, getNotificationSettingB} = this.props;
    // console.log('@deleteRow');
    // console.log(guestID);
    this.setState({loading: true});
    await deleteNotificationSettingB(guestID);
    await this.loadData();
  };

  loadData = async () => {
    const {getNotificationSettingB} = this.props;
    // const value = await AsyncStorage.getItem('@AbdiStore:currentDeviceToken');
    const {error, response} = await getNotificationSettingB(this.state.deviceToken);
    console.log('res: ',response.data);
    const thisThat = this;
    if (!error) {
      thisThat.setState({
        // cellProvider1: response.data.notificationSettings[0].cellProvider1,
        // cellProvider2: response.data.notificationSettings[0].cellProvider2,
        guestArrival: response.data.notificationSettings[0].guestArrival,
        registeredDevices: response.data.registeredDevices,
        // deviceToken: value
      });
    }
    this.setState({loading: false});
  };

  createArray = (items) => {
    let newArr = [];
    for (let x in items){
      let newItem = {
        label: items[x],
        value: items[x],
      };
      newArr.push(newItem);
    }
    return newArr;
  }

  componentDidMount = async () => {
    this.setState({loading: true});
    const {globalData} = this.props;
    const {dropdownArrivalNotificationTypes, dropdownCellCarrierTypes} = globalData;
    const thisThat = this;
    this.setState({
      notiOpts: thisThat.createArray(dropdownArrivalNotificationTypes),
      // providerOpts: thisThat.createArray(dropdownCellCarrierTypes),
    })

    await this.loadData();
    this.setState({loading: false});
  };

  handleChangePicker = name => event => {
    // console.log(name);
    // console.log(event.target.value);
    this.setState({
      ...this.state,
      [name]: event.target.value,
    });
  };

  onSubmit = () => {
    const {
      registeredDevices,
      // cellProvider1,
      // cellProvider2,
      guestArrival,
      notiOpts,
      // providerOpts,
    } = this.state;

    let allData = {
      // cellProvider1,
      // cellProvider2,
      guestArrival,
    };
    this.saveData(allData);
  }

  saveData = async allData => {
    this.setState({loading: true});
    const {editNotificationSettingB} = this.props;
    await editNotificationSettingB(allData);
    // Toast.show('Notification Settings updated.', Toast.SHORT);
    this.setState({alertOpen: true, message: 'Notification Settings updated.', messageType: 'success'});
    await this.loadData();
  };

  showAlert = (message, severity) => {
    // console.log('showing alert');
    const { classes } = this.props;
    return(
      <div className={classes.fab} style={{position: 'fixed'}}>
        <Snackbar open={this.state.alertOpen} autoHideDuration={3000} onClose={this.handleClose}>
          <Alert
            variant="filled"
            severity={severity}
          >
            {message}
          </Alert>
        </Snackbar>
      </div>
    )
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({alertOpen: false});
  };

  render() {
    const {
      registeredDevices,
      // cellProvider1,
      // cellProvider2,
      guestArrival,
      notiOpts,
      // providerOpts,
      deviceToken,
      // textColor,
      darkMode,
      message,
      messageType,
      loading
    } = this.state;
    const { classes } = this.props;

    return (
      <div className={classes.paper}>
        {this.showAlert(message, messageType)}
        <img
          style={{width: window.innerWidth, height: 230, resizeMode: 'contain', margin: 'auto', alignSelf: 'center'}}
          src={NotificationSettingsBanner}
          alt={'NotificationSettingsBanner'}
        />
        <div style={{backgroundColor: headerColor, padding: 20, marginTop: 5, borderRadius: 10, zIndex: 10, elevation: 10, marginBottom: 20}}>
          <VStack style={{borderWidth: 1, borderRadius: 5, borderColor: borderColor, backgroundColor: 'white', marginTop: 15}}>
            <HStack space={3} w='97%' alignItems='center' justifyContent='space-between'>
              <Heading ml={5} mt={5} _dark={{
                color: titleTextColor
              }} color={titleTextColor} fontWeight="medium" size="md">
                Notification Setting
              </Heading>
              <Pressable
                mt={5}
                style={{borderRadius: 5, width: '15%', justifyContent: 'center', alignItems: 'center', background: 'linear-gradient(180deg, #0B7645 30%, #042B1C 90%)'}}
                onPress={() => this.onSubmit()}
                disabled={loading}
                underlayColor={underlayColor}>
                <HStack space={3} justifyContent="space-between" alignItems="center" p="3">
                  <Icon as={<FontAwesomeIcon icon={['fas', 'plus']} size="lg" color={"white"}/>}/>
                  <Heading _dark={{
                      color: "white"
                  }} color="white" fontWeight="medium" size="sm">
                    Save
                  </Heading>
                </HStack>
              </Pressable>
            </HStack>
            <VStack space={5} w="55%" mt={5} ml={5}>
              <FormControl>
                <FormControl.Label>Arrival Notifications via Email?</FormControl.Label>
                <Select
                  native
                  value={guestArrival}
                  onChange={this.handleChangePicker('guestArrival')}
                  inputProps={{
                    name: 'guestArrival',
                    id: 'guestArrival',
                  }}
                >
                  {notiOpts.map((item, index) => {
                    return <option value={item.value} key={index}>{item.label}</option>
                  })}
                </Select>
              </FormControl>
              {/* <Button w="55%" mt="5" mb="5" _light={{ bg: materialColor, _text: { color: "white" } }}
                _dark={{ bg: materialColor, _text: { color: "white" } }}
                //disabled={loading}
                style={{borderRadius: 20}}
                onPress={() => this.onSubmit()}>
                Save
              </Button> */}
            </VStack>

            <Heading _dark={{
              color: titleTextColor
            }} color={titleTextColor} fontWeight="medium" size="md" mt={10} mb={5} ml={5}>
              Registered device(s) for push notifications
            </Heading>
            <VStack ml={5} mr={5} mb={10} style={{width:"95%", borderWidth: 1, borderRadius: 5, borderColor: borderColor, backgroundColor: 'white', marginTop: 5}}>
              <HStack space={2} alignItems="center">
                {/* <Text style={{
                    color: listMainTextColor,
                    fontSize: 16,
                    fontWeight: 'bold',
                    width: '6%',
                    padding: 7,
                    textTransform: 'capitalize',
                    borderRightColor: borderColor,
                    borderRightWidth:1,
                    alignSelf: 'center',
                    textAlign: 'center'
                }}>S.No.</Text> */}
                <Text
                  style={{
                      color: listMainTextColor,
                      fontSize: 16,
                      fontWeight: 'bold',
                      padding: 7,
                      width: '50%',
                      textTransform: 'capitalize',
                      borderRightColor: borderColor,
                      borderRightWidth:1,
                      alignSelf: 'center',
                      // textAlign: 'center'
                  }}>
                  Device Name
                </Text>
                <Text
                  style={{
                      color: listMainTextColor,
                      fontSize: 16,
                      fontWeight: 'bold',
                      padding: 7,
                      width: '35%',
                      textTransform: 'capitalize',
                      borderRightColor: borderColor,
                      borderRightWidth:1,
                      alignSelf: 'center',
                      // textAlign: 'center'
                  }}>
                  Platform
                </Text>
                <Text
                  style={{
                      color: listMainTextColor,
                      fontSize: 16,
                      fontWeight: 'bold',
                      // padding: 7,
                      width: '9%',
                      textTransform: 'capitalize',
                      // borderRightColor: borderColor,
                      // borderRightWidth:1,
                      alignSelf: 'center',
                      // textAlign: 'center'
                  }}>
                  Action
                </Text>
              </HStack>
              <FlatList
                style={{width:"100%", borderColor: borderColor, borderWidth: 1}}
                data={registeredDevices}
                renderItem={({item, index}) => (
                  <Pressable
                    style={{borderBottomColor: borderBottomColor, borderBottomWidth: 1}}
                    // onPress={() => {this.props.history.push({pathname: 'add-edit-guest', state: {data: item}});}}
                    underlayColor={underlayColor}>
                    <Box flex="1">
                      <HStack space={2} alignItems="center">
                        {/* <Text style={{
                            color: listMainTextColor,
                            fontSize: 16,
                            width: '6%',
                            padding: 7,
                            textTransform: 'capitalize',
                            borderRightColor: borderColor,
                            borderRightWidth:1,
                            alignSelf: 'center',
                            textAlign: 'center'
                        }}>{index+1}</Text> */}
                        <Text
                          style={{
                              color: listMainTextColor,
                              fontSize: 16,
                              padding: 7,
                              width: '50%',
                              textTransform: 'capitalize',
                              borderRightColor: borderColor,
                              borderRightWidth:1,
                              alignSelf: 'center',
                              // textAlign: 'center'
                          }}>
                          {item?.deviceName}
                        </Text>
                        <Text
                          style={{
                              color: listMainTextColor,
                              fontSize: 16,
                              padding: 7,
                              width: '35%',
                              textTransform: 'capitalize',
                              borderRightColor: borderColor,
                              borderRightWidth:1,
                              alignSelf: 'center',
                              // textAlign: 'center'
                          }}>
                          {item.platform}
                        </Text>
                        <VStack w="8.8%">
                          <IconButton icon={<Icon as={<FontAwesomeIcon icon={['fas', 'trash']} size="lg" color={deleteColor}/>}/>} onPress={() => {this.deleteRow(item.id)}} />
                        </VStack>
                      </HStack>
                    </Box>
                  </Pressable>
                )}
              />
            </VStack>
          </VStack>
        </div>
        {/* <SwipeList
            listViewData={registeredDevices}
            deviceToken={deviceToken}
            onPressItem={null}
            onPressDelete={did => {
          this.deleteRow(did);
            }}
            darkMode={darkMode}
            />
            {(deviceToken == null) && (
            <Text
          style={{
            color: '#ff0000',
            fontSize: 14,
            fontWeight:'bold',
            alignSelf: 'center'
            // textTransform: 'uppercase',
          }}>
          This device is not registered for push notifications
            </Text>
            )}
        <Text style={[styles.textView5, {color: (darkMode) ? '#7190f0' : 'blue'}]}>Swipe Left to delete</Text> */}
        {loading && (
          <div className={classes.loading}>
            <Spinner size="lg" />
          </div>
        )}
      </div>
    );
  }
}

NotificationSettingsB.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(NotificationSettingsB);
