/* eslint-disable */
import React from 'react';
import Select from '@material-ui/core/Select';
import InputMask from 'react-input-mask';
import {Box, VStack, Flex, FormControl, Input, HStack, Pressable, Button, Icon, Modal, Text, FlatList, Spinner, Heading, IconButton} from 'native-base';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import {materialColor, listMainTextColor, underlayColor, searchBarBorderColor, borderBottomColor, brandColor, headerColor, borderColor, titleTextColor, lightGreen} from '../constants/colors';
import Snackbar from '@material-ui/core/Snackbar';
import {ContactInfoBanner} from '../assets/images';


const styles = {
  paper: {
    flex: 1,
    // padding: 5
  },
  selectStyle: {width: "100%"},
  textStyle: {
    marginLeft: 25,
  },
  fab: {
    position: 'absolute',
    bottom: 30,
    right: 40,
    zIndex: 10,
    elevation: 10
  },
  loading: {
    position: 'absolute',
    left: 420,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    //backgroundColor: '#0000ff47',
    display:'flex'
  },
};

class ContactInfoB extends React.Component {
  constructor() {
    super();
    this.state = {
      phoneNumbers: [],
      homePhone: '',
      email1: '',
      email2: '',
      email3: '',
      cellProvider1: '',
      providerOpts: [],
      phoneType: '',
      phoneTypes: [],
      darkMode: false,
      textColor: '#000000',
      loading: false,
      modalVisible: false,
      message: '',
      messageType: '',
      alertOpen: false,
      buttonText: '',
      idValue: '',
    };
  }

  createArray = items => {
    let newArr = [];
    for (let x in items) {
      let newItem = {
        label: items[x],
        value: items[x],
      };
      newArr.push(newItem);
    }
    return newArr;
  };

  componentDidMount = async () => {
    const {getContactINFOB, getContactINFOBPhones, globalData, contactINFOB} = this.props;
    // console.log('contactINFOB:::::',contactINFOB);
    const {dropdownCellCarrierTypes, phoneTypes} = globalData;
    const thisThat = this;
    this.setState({
      providerOpts: thisThat.createArray(dropdownCellCarrierTypes),
      phoneTypes: thisThat.createArray(phoneTypes)
    });

    var {error, response} = await getContactINFOB();

    if (error) {
    } else {
      //console.log('@componentWillMount');
      let dataSet = response.data.contactSettings[0];
      console.log("emailss::: ", dataSet);
      this.setState({
        email1: dataSet.emailaddress,
        email2: dataSet.secondemail,
        email3: dataSet.thirdemail,
      });
    }
    var {error, response} = await getContactINFOBPhones();
    console.log("phones::: ", response.data.contactPhones);
    if (error) {
    } else {
      this.setState({phoneNumbers: response.data.contactPhones});
    }
  };

  onSubmit=()=> {
    this.callEditContactInfo();
  }

  getValue(setting) {
    const {loginData} = this.props;
    if (loginData) {
      const appOptions = loginData.appOptions;
      for (let x of appOptions) {
        if (x.setting === setting) {
          return x.value;
        }
      }
    }
  }

  _hideModal = () => {
    this.setState({ homePhone: '', cellProvider1: '', phoneType:'', buttonText: '', idValue: '', modalVisible: false });
  }

  _onRequestPressButton(data) {
    this.setState({ homePhone: data.phoneNumber, cellProvider1: data.cellProvider, phoneType: data.phoneType, buttonText: 'Update', idValue: data.id, modalVisible: true });
  }

  _onRequestPressButtonAdd(data) {
    this.setState({ homePhone: '', cellProvider1: '', phoneType:'', buttonText: 'Save', idValue: '', modalVisible: true });
  }

  callEditContactInfo = async () => {
    this.setState({loading: true, alertOpen: true, message: 'Saving...', messageType: 'info'});
    const {editContactINFOB} = this.props;
    const {
      email1,
      email2,
      email3,
      cellProvider1,
    } = this.state;

    var reEmail = /^(?:[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+\.)*[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!\.)){0,61}[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/;

    if (email1 != null && email1.length>0 && new RegExp(reEmail).test(email1) === false) {
      this.setState({ message: 'E-mail 1 is not in good format', loading: false, messageType: 'error' });
      return;
    }

    if (email2 != null && email2.length>0 && new RegExp(reEmail).test(email2) === false) {
      this.setState({ message: 'E-mail 2 is not in good format', loading: false, messageType: 'error' });
      return;
    }

    if (email3 != null && email3.length>0 && new RegExp(reEmail).test(email3) === false) {
      this.setState({ message: 'E-mail 3 is not in good format', loading: false, messageType: 'error' });
      return;
    }

    let editData = {
      emailaddress: email1,
      guestarrival: 'Alert All',
      secondemail: email2,
      thirdemail: email3,
    };

    console.log('editData: ',editData);

    await editContactINFOB(editData);
    this.setState({loading: false, alertOpen: true, message: 'Contact info updated.', messageType: 'success'});
  };

  callEditPhoneInfo = async () => {
    this.setState({loading: true, alertOpen: true, message: 'Saving...', messageType: 'info'});
    const {addContactINFOBPhones, putContactINFOBPhones, getContactINFOBPhones} = this.props;
    const {
      homePhone,
      phoneType,
      cellProvider1,
      buttonText,
      idValue
    } = this.state;
    console.log('homePhone: '+homePhone+' phoneType: '+phoneType+' cellProvider: '+cellProvider1+' idValue: '+idValue);
    if(homePhone == ''){
      this.setState({ message: 'Phone number should not be empty', loading: false, messageType: 'error' });
      return;
    }

    if(homePhone.length > 0 && homePhone.length < 10){
      this.setState({ message: 'Phone number should be of 10 digits', loading: false, messageType: 'error' });
      return;
    }

    if(phoneType == ''){
      this.setState({ message: 'Select Phone Type', loading: false, messageType: 'error' });
      return;
    }

    if(cellProvider1 == ''){
      this.setState({ message: 'Select Cell Provider', loading: false, messageType: 'error' });
      return;
    }

    if (buttonText === 'Save') {
      let addPhoneData = {
        CellProvider: cellProvider1,
        PhoneNumber: homePhone,
        PhoneType: phoneType
      };
      console.log('addPhoneData: ',addPhoneData);

      await addContactINFOBPhones(addPhoneData);
      this.setState({loading: false, alertOpen: true, message: 'Phone info Added.', messageType: 'success'});
    } else {
      let editPhoneData = {
        ID: idValue,
        CellProvider: cellProvider1,
        PhoneNumber: homePhone,
        PhoneType: phoneType
      };
      console.log('editPhoneData: ',editPhoneData);

      await putContactINFOBPhones(editPhoneData);
      this.setState({loading: false, alertOpen: true, message: 'Phone info updated.', messageType: 'success'});
    }

    var {error, response} = await getContactINFOBPhones();
    console.log("phones::: ", response.data.contactPhones);
    if (error) {
    } else {
      this.setState({phoneNumbers: response.data.contactPhones});
      this._hideModal();
    }
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({alertOpen: false});
  };

  showAlert = (message, severity) => {
    // console.log('showing alert');
    const { classes } = this.props;
    return(
      <div className={classes.fab} style={{position: 'fixed'}}>
        <Snackbar open={this.state.alertOpen} autoHideDuration={3000} onClose={this.handleClose}>
          <Alert
            variant="filled"
            severity={severity}
          >
            {message}
          </Alert>
        </Snackbar>
      </div>
    )
  }

  handleChangePicker = name => event => {
    // console.log(name);
    // console.log(event.target.value);
    this.setState({
      ...this.state,
      [name]: event.target.value,
    });
  };

  handleChange = (e) => {
    // console.log(e.target.name);
    // console.log(e.target.value);
    this.setState({homePhone: e.target.value});
    // this.setState(Object.assign(this.state, { [e.target.name]: e.target.value }))
  }

  deleteItem = async id => {
    const {delContactINFOBPhones, getContactINFOBPhones} = this.props;
    // console.log('@deleteRow');
    this.setState({loading: true, alertOpen: true, message: 'Deleting...', messageType: 'info'});
    await delContactINFOBPhones(id);
    this.setState({loading: false, alertOpen: true, message: 'Deleted', messageType: 'success'});
    var {error, response} = await getContactINFOBPhones();
    console.log("phones::: ", response.data.contactPhones);
    if (error) {
    } else {
      this.setState({phoneNumbers: response.data.contactPhones});
    }
  };


  render() {
    const {
      homePhone,
      phoneNumbers,
      email1,
      email2,
      email3,
      cellProvider1,
      providerOpts,
      phoneTypes,
      phoneType,
      textColor,
      darkMode,
      loading,
      message,
      messageType,
      modalVisible
    } = this.state;

    const { classes } = this.props;

    return (
      <div className={classes.paper}>
        {this.showAlert(message, messageType)}
        <img
          style={{width: window.innerWidth, height: 230, resizeMode: 'contain', margin: 'auto', alignSelf: 'center'}}
          src={ContactInfoBanner}
          alt={'ContactInfoBanner'}
        />
        <div style={{backgroundColor: headerColor, padding: 20, marginTop: 5, borderRadius: 10, zIndex: 10, elevation: 10, marginBottom: 20}}>
          <VStack style={{borderWidth: 1, borderRadius: 5, borderColor: borderColor, backgroundColor: 'white', marginTop: 15}}>
            <Modal isOpen={modalVisible} onClose={() => this._hideModal()} useRNModal={true}>
              <Modal.Content maxWidth="500px">
                <Modal.CloseButton />
                <Modal.Header _text={{color: "#FFFFFF"}} style={{backgroundColor: brandColor}}>Add/Edit Phone</Modal.Header>
                <Modal.Body>

                  <VStack space={3}>
                    <FormControl>
                      <FormControl.Label>Phone Number</FormControl.Label>
                      <InputMask
                        mask="999 999 9999"
                        value={homePhone}
                        onChange={this.handleChange}
                      >
                        {() => <Input
                          // variant="rounded"
                          borderColor={borderColor}
                          size="lg"
                          autoCapitalize={false}
                          autoCorrect={false}
                          placeholder={'Enter phone'}
                          InputLeftElement={<IconButton icon={<Icon as={<FontAwesomeIcon icon={['fas', 'phone']} size="lg"/>} />}/>}
                               />}
                      </InputMask>
                    </FormControl>

                    <HStack space={2} justifyContent="space-between" mt="10">
                      <FormControl w='45%'>
                        <FormControl.Label>Cell Provider </FormControl.Label>
                        <Select
                          native
                          className={classes.selectStyle}
                          value={cellProvider1}
                          onChange={this.handleChangePicker('cellProvider1')}
                          inputProps={{
                            name: 'cellProvider1',
                            id: 'age-native-simple',
                          }}
                        >

                          {providerOpts.map((item, index) => {
                            return <option value={item.value} key={index}>{item.label}</option>
                          })}

                        </Select>
                      </FormControl>

                      <FormControl w='45%'>
                        <FormControl.Label>Phone Type </FormControl.Label>
                        <Select
                          native
                          className={classes.selectStyle}
                          value={phoneType}
                          onChange={this.handleChangePicker('phoneType')}
                          inputProps={{
                            name: 'phoneType',
                            id: 'age-native-simple',
                          }}
                        >

                          {phoneTypes.map((item, index) => {
                            return <option value={item.value} key={index}>{item.label}</option>
                          })}

                        </Select>
                      </FormControl>
                    </HStack>
                  </VStack>
                </Modal.Body>
                <Modal.Footer>
                  <Button.Group space={2} mr={20}>
                    <Button w='75%' mt="1" _light={{ bg: '#ff0000', _text: { color: "white" } }}
                      _dark={{ bg: "#ff0000", _text: { color: "white" } }}
                      // disabled={loading}
                      style={{borderRadius: 5}}
                      onPress={() => {this._hideModal();}}
                    >
                      Cancel
                    </Button>

                    <Button w='75%' mt="1" _light={{ bg: materialColor, _text: { color: "white" } }}
                      _dark={{ bg: materialColor, _text: { color: "white" } }}
                      // disabled={loading}
                      style={{borderRadius: 5}}
                      onPress={() => {this.callEditPhoneInfo();}}
                    >
                      {this.state.buttonText}
                    </Button>
                  </Button.Group>
                </Modal.Footer>
              </Modal.Content>
            </Modal>

            <VStack space={5} m={6} justifyContent="center" >
              <Heading _dark={{
                color: titleTextColor
              }} color={titleTextColor} fontWeight="medium" size="md">
                Email Addresses:
              </Heading>

              <HStack space={5} w='98%' alignItems='center' justifyContent='space-between'>

                <Input
                  // variant="rounded"
                  borderColor={borderColor}
                  size="lg"
                  w='32%'
                  // isReadOnly={!isEditableText}
                  // style={{ color: (this.state.darkMode) ? '#ffffff' : '#000000' }}
                  onChangeText={email1 => this.setState({email1})}
                  value={email1}
                  autoCapitalize={false}
                  autoCorrect={false}
                  placeholder={'First E-mail'}
                  // placeholderTextColor={(darkMode) ? '##666' : '#C7C7CD'}
                  InputLeftElement={<IconButton icon={<Icon as={<FontAwesomeIcon icon={['fas', 'envelope']} size="lg"/>} />}/>}
                  // InputLeftElement={<Icon as={<MaterialIcons name="person" />} size={5} ml="2" color="muted.400" />}
                  returnKeyType='next'
                />
                <Input
                  // variant="rounded"
                  borderColor={borderColor}
                  size="lg"
                  w='32%'
                  // isReadOnly={!isEditableText}
                  // style={{ color: (this.state.darkMode) ? '#ffffff' : '#000000' }}
                  onChangeText={email2 => this.setState({email2})}
                  value={email2}
                  autoCapitalize={false}
                  autoCorrect={false}
                  placeholder={'Second E-mail'}
                  // placeholderTextColor={(darkMode) ? '##666' : '#C7C7CD'}
                  InputLeftElement={<IconButton icon={<Icon as={<FontAwesomeIcon icon={['fas', 'envelope']} size="lg"/>} />}/>}
                  // InputLeftElement={<Icon as={<MaterialIcons name="person" />} size={5} ml="2" color="muted.400" />}
                  returnKeyType='next'
                />
                <Input
                  // variant="rounded"
                  borderColor={borderColor}
                  size="lg"
                  w='32%'
                  // isReadOnly={!isEditableText}
                  // style={{ color: (this.state.darkMode) ? '#ffffff' : '#000000' }}
                  onChangeText={email3 => this.setState({email3})}
                  value={email3}
                  autoCapitalize={false}
                  autoCorrect={false}
                  placeholder={'Third E-mail'}
                  // placeholderTextColor={(darkMode) ? '##666' : '#C7C7CD'}
                  InputLeftElement={<IconButton icon={<Icon as={<FontAwesomeIcon icon={['fas', 'envelope']} size="lg"/>} />}/>}
                  // InputLeftElement={<Icon as={<MaterialIcons name="person" />} size={5} ml="2" color="muted.400" />}
                  returnKeyType='next'
                />
              </HStack>

              <VStack m={5} alignItems='flex-end'>
                <Pressable
                  style={{borderRadius: 5, width: '25%', justifyContent: 'center', alignItems: 'center', background: 'linear-gradient(180deg, #0B7645 30%, #042B1C 90%)'}}
                  onPress={() => this.onSubmit()}
                  disabled={loading}
                  underlayColor={underlayColor}>
                  <HStack space={3} justifyContent="space-between" alignItems="center" p="4">
                    <Icon as={<FontAwesomeIcon icon={['fas', 'user-plus']} size="lg" color={"white"}/>}/>
                    <Heading _dark={{
                      color: "white"
                    }} color="white" fontWeight="medium" size="sm">
                      Save
                    </Heading>
                  </HStack>
                </Pressable>
                {/* <Button _light={{ bg: materialColor, _text: { color: "white" } }}
                  _dark={{ bg: materialColor, _text: { color: "white" } }}
                  disabled={loading}
                  style={{borderRadius: 20}}
                  onPress={() => this.onSubmit()}>
                  Save
                </Button> */}
              </VStack>

              <HStack space={2} alignItems="center" mt={5}>
                <Heading _dark={{
                  color: titleTextColor
                }} color={titleTextColor} fontWeight="medium" size="md">
                  Phone Numbers:
                </Heading>
                <Button mt={1} ml={5} _light={{ bg: materialColor, _text: { color: "white" } }}
                  _dark={{ bg: materialColor, _text: { color: "white" } }}
                  // disabled={loading}
                  style={{borderRadius: 5}}
                  onPress={() => {this._onRequestPressButtonAdd();}}
                >
                  Add Phone
                </Button>
              </HStack>
              {(phoneNumbers.length>0) && (
                <HStack space={2} alignItems="center">
                  <Text style={{
                    color: listMainTextColor,
                    fontSize: 16,
                    fontWeight: 'bold',
                    width: '6%',
                    padding: 7,
                    textTransform: 'capitalize',
                    borderRightColor: borderColor,
                    borderRightWidth:1,
                    alignSelf: 'center',
                    textAlign: 'center'
                  }}>S.No.</Text>
                  <Text
                    style={{
                      color: listMainTextColor,
                      fontSize: 16,
                      fontWeight: 'bold',
                      padding: 7,
                      width: '30%',
                      textTransform: 'capitalize',
                      borderRightColor: borderColor,
                      borderRightWidth:1,
                      alignSelf: 'center',
                      // textAlign: 'center'
                    }}>
                    Cell Provider
                  </Text>
                  <Text
                    style={{
                      color: listMainTextColor,
                      fontSize: 16,
                      fontWeight: 'bold',
                      padding: 7,
                      width: '29%',
                      textTransform: 'capitalize',
                      borderRightColor: borderColor,
                      borderRightWidth:1,
                      alignSelf: 'center',
                      // textAlign: 'center'
                    }}>
                    Phone Number
                  </Text>
                  <Text
                    style={{
                      color: listMainTextColor,
                      fontSize: 16,
                      fontWeight: 'bold',
                      padding: 7,
                      width: '29%',
                      textTransform: 'capitalize',
                      borderRightColor: borderColor,
                      borderRightWidth:1,
                      alignSelf: 'center',
                      // textAlign: 'center'
                    }}>
                    Phone Type
                  </Text>
                  <Text
                    style={{
                      color: listMainTextColor,
                      fontSize: 16,
                      fontWeight: 'bold',
                      padding: 7,
                      // width: '6%',
                      textTransform: 'capitalize',
                      // borderRightColor: borderColor,
                      // borderRightWidth:1,
                      alignSelf: 'center',
                      // textAlign: 'center'
                    }}>
                    Action
                  </Text>
                </HStack>
              )}
              <FlatList
                style={{width:"100%", borderColor: borderColor, borderWidth: 1}}
                data={phoneNumbers}
                //keyExtractor={(item, index) => String(item.id)}
                //Extractor={item => item.id}
                renderItem={({item, index}) => (
                  <Pressable
                    style={{borderBottomColor: borderBottomColor, borderBottomWidth: 1}}
                    onPress={() => this._onRequestPressButton(item)}
                    underlayColor={underlayColor}
                  >
                    <Box flex="1">
                      <HStack space={2} alignItems="center">
                        <Text style={{
                            color: listMainTextColor,
                            fontSize: 16,
                            width: '6%',
                            padding: 7,
                            textTransform: 'capitalize',
                            borderRightColor: borderColor,
                            borderRightWidth:1,
                            alignSelf: 'center',
                            textAlign: 'center'
                        }}>{index+1}</Text>

                        <Text
                          style={{
                              color: listMainTextColor,
                              fontSize: 16,
                              padding: 7,
                              width: '30%',
                              textTransform: 'capitalize',
                              borderRightColor: borderColor,
                              borderRightWidth:1,
                              alignSelf: 'center',
                              // textAlign: 'center'
                          }}>
                          {item.cellProvider}
                        </Text>

                        <Text
                          style={{
                              color: listMainTextColor,
                              fontSize: 16,
                              padding: 7,
                              width: '29%',
                              textTransform: 'capitalize',
                              borderRightColor: borderColor,
                              borderRightWidth:1,
                              alignSelf: 'center',
                              // textAlign: 'center'
                          }}>
                          {item.phoneNumber}
                        </Text>

                        <Text
                          style={{
                              color: listMainTextColor,
                              fontSize: 16,
                              padding: 7,
                              width: '29%',
                              textTransform: 'capitalize',
                              borderRightColor: borderColor,
                              borderRightWidth:1,
                              alignSelf: 'center',
                              // textAlign: 'center'
                          }}>
                          {item.phoneType}
                        </Text>
                        <VStack w='5.4%'>
                          <IconButton icon={<Icon as={<FontAwesomeIcon icon={['fas', 'trash']} size="lg" color={deleteColor}/>}/>} onPress={() => {this.deleteItem(item.id)}} />
                        </VStack>
                      </HStack>
                    </Box>
                  </Pressable>
                )}
              />

            </VStack>
          </VStack>
          {this.getValue('ContactsPageMessage') && (
            <VStack style={{marginTop: 10, backgroundColor: lightGreen, justifyContent: 'center', alignItems: 'center',marginHorizontal:10, borderRadius: 5}}>
              <Text style={{padding: 5, color: "#000000", textAlign:'center'}}>
                {this.getValue('ContactsPageMessage')}
              </Text>
            </VStack>
          )}
          {/* {this.getValue('ContactsPageMessage') && (
            <View style={{position:"absolute", bottom:15, width: '100%', backgroundColor: "#0000ff", justifyContent: 'center', alignItems: 'center'}}>
              <Text style={{padding: 5, color: "#ffffff"}}>
            {this.getValue('ContactsPageMessage')}
              </Text>
            </View>
          )} */}
        </div>
        {loading && (
          <div className={classes.loading}>
            <Spinner size="lg" />
          </div>
        )}
      </div>
    );
  }
}

ContactInfoB.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(ContactInfoB);
