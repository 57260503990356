// import React, { Component, Fragment } from 'react'
// //import { Link, withRouter } from 'react-router-dom'
// import {
//   AppBar, Toolbar, IconButton, Hidden,
//   Drawer, CssBaseline, Typography
// } from '@material-ui/core';
// import { GA3, ABDILOGO } from '../assets/images';
// import { withStyles } from '@material-ui/core/styles';
// // import { Menu } from '@material-ui/icons';
// //import { compose } from 'recompose';
// import MainMenuScreen from '../redux/MainMenu/MainMenu.container';
// import {headerColor, brandColor} from '../constants/colors';

// const drawerWidth = "24%"

// const styles = theme => ({
//   // root: {
//   //   flexGrow: 1,
//   //   zIndex: 1,
//   //   overflow: 'hidden',
//   //   position: 'relative',
//   //   display: 'flex',
//   //   width: '100%',
//   // },
//   // appBar: {
//   //   zIndex: theme.zIndex.drawer + 1,
//   // },
//   // navIconHide: {
//   //   [theme.breakpoints.up('md')]: {
//   //     display: 'none',
//   //   },
//   // },
//   // toolbar: theme.mixins.toolbar,
//   // drawerPaper: {
//   //   width: drawerWidth,
//   //   [theme.breakpoints.up('md')]: {
//   //     position: 'relative',
//   //   },
//   // },
//   // content: {
//   //   //display: 'flex',
//   //   flexGrow: 1,
//   //   backgroundColor: "#fff",
//   //   //padding: theme.spacing(3),
//   // },
//   // nested: {
//   //   paddingLeft: theme.spacing(4),
//   // },

//   root: {
//       display: 'flex'
//     },
//     appBar: {
//       width: `calc(100% - ${drawerWidth})`,
//       marginLeft: drawerWidth,
//     },
//     drawer: {
//       width: drawerWidth,
//       // flexShrink: 0,
//     },
//     drawerPaper: {
//       width: drawerWidth,
//       backgroundColor: '#ffffff',
//     },
//     // necessary for content to be below app bar
//     toolbar: theme.mixins.toolbar,
//     content: {
//       flexGrow: 1,
//       // backgroundColor: theme.palette.background.default,
//       backgroundColor: '#ffffff',
//       padding: theme.spacing(3),
//     },
//     bColor: {
//       background: 'linear-gradient(45deg, #0B7645 30%, #042B1C 90%)',
//       overflow: 'auto',
//       // maxHeight: 830,
//       marginBottom: 35,
//       height: '100%'
//     },
//     appBarFooter: {
//       top: 'auto',
//       bottom: 0,
//       // background: theme.palette.background.paper,
//       background: 'linear-gradient(45deg, #0B7645 30%, #042B1C 90%)',
//       zIndex: theme.zIndex.drawer + 1,
//     },
//   toolbarFooter: {
//     minHeight: 36,
//     display: 'flex',
//     justifyContent: 'center',
//   },
//   text: {
//     color:"#58595B"
//   },

// })

// class Layout extends Component {
//   // state = {
//   //   mobileOpen: false
//   // }

//   // handleDrawerToggle = () => {
//   //   this.setState({ mobileOpen: !this.state.mobileOpen })
//   // }

//   render() {
//     const { classes,
//       // location: { pathname },
//       children } = this.props;
//     // const { mobileOpen } = this.state;
//     // console.log('pathname: ', pathname);
//     // const drawer = (
//     //   <div>
//     //     <Hidden smDown>
//     //       <div className={classes.toolbar} />
//     //     </Hidden>
//     //     <MainMenuScreen/>
//     //     {/*<MenuList>
//     //       <MenuItem component={Link} to="/contact-info" selected={'/contact-info' === pathname}>
//     //         Home
//     //       </MenuItem>
//     //        <MenuItem component={Link} to="/writers" selected={'/writers' === pathname}>
//     //         Writers
//     //       </MenuItem>
//     //       <MenuList>
//     //         {writers.map(({ id, name }) => {
//     //       const to = `/writers/${id}`
//     //
//     //       return <MenuItem
//     //       to={to}
//     //       key={id}
//     //       className={classes.nested}
//     //       component={Link}
//     //       selected={to === pathname}
//     //       >
//     //       {name}
//     //       </MenuItem>
//     //         })}
//     //       </MenuList>
//     //     </MenuList>*/}
//     //   </div>
//     // )

//     return (
//       <div className={classes.root}>
//         <CssBaseline/>
//         <AppBar position="fixed" className={classes.appBar} style={{backgroundColor: headerColor}}>
//           <Toolbar>
//             {/* <IconButton
//               color="inherit"
//               aria-label="open drawer"
//               onClick={this.handleDrawerToggle}
//               className={classes.navIconHide}
//               >
//               <Menu />
//             </IconButton> */}
//             <Typography variant="h5" noWrap className={classes.text}>
//               ABDi-GateAccess.NET
//             </Typography>

//             {/* <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
//               <img
//                 style={{width: window.innerWidth * (20 / 100), resizeMode: 'contain', margin: 'auto', alignSelf: 'center'}}
//                 src={LOGO}
//                 alt={'main bottom'}
//               />
//             </div> */}

//           </Toolbar>
//         </AppBar>
//         {/* <Hidden mdUp>
//           <Drawer
//             variant="temporary"
//             open={mobileOpen}
//             onClose={this.handleDrawerToggle}
//             classes={{
//           paper: classes.drawerPaper,
//             }}
//             ModalProps={{
//           keepMounted: true, // Better open performance on mobile.
//             }}
//           >
//             {drawer}
//           </Drawer>
//         </Hidden> */}
//         {/* <Hidden smDown implementation="css"> */}
//         <Drawer
//           className={classes.drawer}
//           variant="permanent"
//           classes={{
//             paper: classes.drawerPaper,
//           }}
//           anchor="left"
//         >
//           <div className={classes.toolbar} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: brandColor}}>
//             <img
//               style={{width: window.innerWidth * (20 / 100), resizeMode: 'contain', margin: 10, alignSelf: 'center'}}
//               src={ABDILOGO}
//               alt={'main bottom'}
//             />
//           </div>
//           <div className={classes.bColor}>
//             <MainMenuScreen/>
//           </div>
//           {/* {drawer} */}
//         </Drawer>
//         {/* </Hidden> */}
//         <main className={classes.content}>
//           <div className={classes.toolbar} />
//           {children}
//         </main>
//         <AppBar position="fixed" className={classes.appBarFooter}>
//           <Toolbar className={classes.toolbarFooter}>
//             <Typography variant="body2" color="white">
//               © {new Date().getFullYear()} Applications by Design, Inc.
//             </Typography>
//           </Toolbar>
//         </AppBar>
//       </div>
//     )
//   }
// }
// export default withStyles(styles)(Layout);
// // export default compose(
// //   withRouter,
// //   withStyles(styles)
// // )(Layout)

import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import useScreenType from "react-screentype-hook";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MainMenuScreen from '../redux/MainMenu/MainMenu.container';
import {headerColor, brandColor, headerTitleColor} from '../constants/colors';
import { GA3, ABDILOGO } from '../assets/images';

const drawerWidth = 400;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  root1: {
    // display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#ffffff',
  },
  content: {
    flexGrow: 1,
    backgroundColor: '#ffffff',
    padding: theme.spacing(3),
  },
  bColor: {
    background: 'linear-gradient(45deg, #0B7645 30%, #042B1C 90%)',
    overflow: 'auto',
    // maxHeight: 830,
    marginBottom: 35,
    height: '130%'
  },
  appBarFooter: {
    top: 'auto',
    bottom: 0,
    // background: theme.palette.background.paper,
    background: 'linear-gradient(45deg, #0B7645 30%, #042B1C 90%)',
    zIndex: theme.zIndex.drawer + 1,
  },
  toolbarFooter: {
    minHeight: 36,
    display: 'flex',
    justifyContent: 'center',
  },
  text: {
    color: headerTitleColor
  },
}));

function Layout(props) {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const screenType = useScreenType();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  console.log('pathname: ', props.children._owner.pendingProps.location.pathname);
  console.log('screenType: ', screenType);
  let pathname = 'ABDi-GateAccess.NET';
  if(props.children._owner.pendingProps.location.pathname == '/dashboard/manage-guest'){
    pathname = 'Manage guest';
  } else if(props.children._owner.pendingProps.location.pathname == '/dashboard/add-edit-multiple-guest'){
    pathname = 'Multiple guest';
  } else if(props.children._owner.pendingProps.location.pathname == '/dashboard/recent-visitors'){
    pathname = 'Recent Visitor';
  } else if(props.children._owner.pendingProps.location.pathname == '/dashboard/recent-visitors-details'){
    pathname = 'Recent Visitor Details';
  } else if(props.children._owner.pendingProps.location.pathname == '/dashboard'){
    pathname = 'ABDi-GateAccess.NET';
  } else if(props.children._owner.pendingProps.location.pathname == '/dashboard/add-edit-guest'){
    pathname = 'Add-Edit guest';
  } else if(props.children._owner.pendingProps.location.pathname == '/dashboard/notification-settings'){
    pathname = 'Notification Settings';
  } else if(props.children._owner.pendingProps.location.pathname == '/dashboard/notification-settings-details'){
    pathname = 'Notification Settings';
  } else if(props.children._owner.pendingProps.location.pathname == '/dashboard/contact-info'){
    pathname = 'Contact Info';
  } else if(props.children._owner.pendingProps.location.pathname == '/dashboard/contact-info-details'){
    pathname = 'Contact Info';
  } else if(props.children._owner.pendingProps.location.pathname == '/dashboard/manage-pets'){
    pathname = 'Manage Pets';
  } else if(props.children._owner.pendingProps.location.pathname == '/dashboard/add-edit-pet'){
    pathname = 'Add-Edit pet';
  } else if(props.children._owner.pendingProps.location.pathname == '/dashboard/registered-vehicles'){
    pathname = 'Registered Vehicles';
  } else if(props.children._owner.pendingProps.location.pathname == '/dashboard/registered-vehicles-details'){
    pathname = 'Registered Vehicles Details';
  } else if(props.children._owner.pendingProps.location.pathname == '/dashboard/travel-info'){
    pathname = 'Travel Info';
  } else if(props.children._owner.pendingProps.location.pathname == '/dashboard/add-edit-travel'){
    pathname = 'Add-Edit Travel';
  } else if(props.children._owner.pendingProps.location.pathname == '/dashboard/add-edit-emergency-contact'){
    pathname = 'Add-Edit Emergency Contact';
  } else if(props.children._owner.pendingProps.location.pathname == '/dashboard/emergency-contact'){
    pathname = 'Emergency Contacts';
  } else if(props.children._owner.pendingProps.location.pathname == '/dashboard/manage-login-credentials'){
    pathname = 'Manage login credentials';
  } else {
    pathname = "ABDi-GateAccess.NET";
  }
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleDrawerToggle1 = () => {
  };

  const drawer = (
    <div>
      <div className={classes.toolbar} style={{display: 'flex', alignItems: 'center', justifyContent: (screenType.isMobile) ? 'flex-start' : 'center', backgroundColor: brandColor}}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <CloseIcon style={{ color: "#fff", fontSize: 40, marginLeft: 10}}/>
          </IconButton>
          <img
            style={{width: (screenType.isMobile) ? document.documentElement.clientWidth * (50 / 100) : document.documentElement.clientWidth * (20 / 100), resizeMode: 'contain', margin: 10, alignSelf: 'center'}}
            src={ABDILOGO}
            alt={'main bottom'}
          />
      </div>
      <div className={classes.bColor}>
          <MainMenuScreen onPress={(screenType.isMobile) ? handleDrawerToggle : handleDrawerToggle1}/>
      </div>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={(screenType.isMobile) ? classes.root1 : classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar} style={{backgroundColor: headerColor}}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon style={{ color: "#000", fontSize: 40 }}/>
          </IconButton>
          <Typography variant="h5" noWrap className={classes.text}>
            {pathname}
          </Typography>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
          {props.children}
      </main>
      <AppBar position="fixed" className={classes.appBarFooter}>
        <Toolbar className={classes.toolbarFooter}>
          <Typography variant="body2" color="white">
            © {new Date().getFullYear()} Applications by Design, Inc.
          </Typography>
        </Toolbar>
      </AppBar>
    </div>
  );
}

Layout.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Layout;
